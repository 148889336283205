import CardList from 'components/Card/CardList';
import CardsLoader from 'components/Loaders/CardsLoader';
import { useModelList } from 'features/model-listing/api/queries';
import { useGetModelOwners } from 'features/model-listing/hooks/useGetModelOwners';
import { useModelListFiltersStore } from 'features/model-listing/state/model-list-store';
import { ModelList } from 'features/model-listing/types';
import { useLoggedInUser } from 'features/people/api';
import { useEffect } from 'react';
import { ModelCard } from '../Cards/ModelCard';
import { FilterBadges } from './FilterBadges';

interface ModelContainerProps {
  setFiltersCount: (value: number) => void;
}

export default function ModelListing({ setFiltersCount }: ModelContainerProps) {
  const { data: user } = useLoggedInUser();
  //getting logged-in user id
  const ussId = user ? user.ussId.toString() : '';
  //store
  const store = useModelListFiltersStore();
  //get model list
  const { data, status } = useModelList(ussId, { ...store.params });
  //get owner list
  const modelOwnersList = useGetModelOwners(data?.items ?? []);

  useEffect(() => {
    //setting owners and status arrays to store
    if (data?.items) {
      //if filter is not applied then only set model-owner array
      if (!store.isFilterApplied) store.setModelOwnersArr(modelOwnersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      {status === 'pending' && (
        <CardsLoader variant="row-grid" count={9} hasAvatar />
      )}
      {status === 'success' && (
        <>
          <FilterBadges setCount={setFiltersCount} />
          <CardList<ModelList>
            items={data?.items ?? []}
            variant="row-grid"
            renderItem={(listItem) => <ModelCard modelListItem={listItem} />}
            noResults={{ title: 'No Records Found', description: 'No model records found, Please modify the search criteria to view the records' }}
          />
        </>
      )}
    </div>
  );
}
