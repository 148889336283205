import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from '@azure/msal-react';
import { intersection } from 'lodash';
import useUserRoles from 'hooks/useUserRoles';
import { Navigation } from '@uss/react-components';
import SideNavLink from './SideNavLink';
import { useMsal } from '@azure/msal-react';
import { MenuItem } from 'types/common';
import useWindowSize from 'hooks/useWindowSize';
import { PAGE_ROLES } from 'roles';
import { wrapAsyncFunction } from 'utils';
import { AddPTOPlantType } from 'features/paid-time-off/types/employee-pto';
import { usePersonDetails } from 'features/people/api';
import useEnableFeatureFlags from 'hooks/useEnableFeatureFlags';

interface NavLinkProps {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const adminLinks: MenuItem[] = [
  {
    title: 'Crew Maintenance',
    roles: PAGE_ROLES.CREW_MAINTENANCE,
    path: '/admin/crew-maintenance',
  },
  {
    title: 'Org Maintenance',
    roles: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
    path: '/admin/org-structure',
  },
  {
    title: 'Pay Code Maintenance',
    roles: PAGE_ROLES.PAY_CODE_MAINTENANCE,
    path: '/admin/paycodes',
  },
  {
    title: 'Security',
    roles: PAGE_ROLES.SECURITY,
    path: '/admin/security',
  },
];

const refereceLinks: MenuItem[] = [
  {
    title: 'Holiday List',
    roles: PAGE_ROLES.HOLIDAY_LIST,
    path: 'reference-links/holiday-list',
  },
  {
    title: 'Pay Cycle Schedule',
    roles: PAGE_ROLES.PAY_CYCLES,
    path: '/reference-links/pay-cycles',
  },
];

const paidTimeOffLinks: MenuItem[] = [
  {
    title: 'Crew PTO (NU)',
    roles: PAGE_ROLES.CREW_PTO,
    path: 'paid-time-off/crew-pto',
  },
  {
    title: 'Employee Search (NU)',
    roles: PAGE_ROLES.SEARCH_EMP_PTO,
    path: 'paid-time-off/employees',
  },
  {
    title: 'Requests (NU)',
    roles: PAGE_ROLES.PTO_REQUESTS,
    path: 'paid-time-off/requests',
  },
];

const SideNav = ({ setMenuOpen }: NavLinkProps): JSX.Element => {
  const { roles, uss_id } = useUserRoles();
  const { width } = useWindowSize();
  const { data: user } = usePersonDetails('me');

  const schedulingLinks: MenuItem[] = [
    {
      title: 'Schedules (NU)',
      roles: PAGE_ROLES.NON_UNION_SCHED_CREW_LISTING,
      path: '/non-union-scheduling/crew-listing',
    },
  ];

  const payLinks: MenuItem[] = [
    {
      title: 'Approvals (NU)',
      roles: PAGE_ROLES.NON_UNION_PAY,
      path: 'non-union-pay/overview',
    },
    {
      title: 'Pay Pulloff',
      roles: PAGE_ROLES.PAY_PULLOFF,
      path: '/pay/pay-pulloff',
    },
  ];

  const probationaryEmployeesLinks: MenuItem[] = [
    {
      title: 'Area Manager View',
      roles: PAGE_ROLES.PROB_EMP_AREA_MANAGER_VIEW,
      path: '/probationary-tracking/area-manager-view/employees',
    },
    {
      title: 'Reviewer View',
      roles: PAGE_ROLES.PROB_EMP_REVIEWER_VIEW,
      path: '/probationary-tracking/reviewer-view/employees',
    },
    {
      title: 'HR/LR',
      roles: PAGE_ROLES.PROB_EMP_HR_LR,
      path: '/probationary-tracking/hr-lr-view/employees',
    },
    {
      title: 'Employee Acknowledgement',
      roles: PAGE_ROLES.PROB_EMP_EMPLOYEE_ACKNOWLEDGEMENT,
      path: `/probationary-tracking/employee-acknowledgement/${uss_id}`,
    },
    {
      title: 'Bargaining Unit Total',
      roles: PAGE_ROLES.PROB_EMP_BARGAINING_UNIT_TOTAL,
      path: 'probationary-tracking/bargaining-unit-total',
    },
  ];

  const { pathname } = useLocation();
  const splitLocation = pathname.split('/');

  const { instance } = useMsal();
  const azureLogout = async () => {
    try {
      await instance.logoutRedirect();
    } catch (err) {
      // handle error
    }
  };

  const handleLinkClick = () => {
    if (width && width <= 1024) {
      setMenuOpen(false);
    } else {
      return;
    }
  };

  const checkAutherizedMenu = (menus: MenuItem[]) => {
    let isValid = false;
    for (const menu of menus) {
      if (intersection(roles, menu.roles).length > 0) {
        isValid = true;
      }
    }
    return isValid;
  };

  //Only Integrated plant type emp are allowed to view this page
  if (
    user?.plantType === AddPTOPlantType.integratedMill ||
    user?.plantType === AddPTOPlantType.office
  ) {
    payLinks.push({
      title: 'Mileage Approval',
      roles: PAGE_ROLES.MILEAGE_APPROVAL,
      path: '/pay/mileage-approval',
    });
  }

  const { enabledFeatureFlags } = useEnableFeatureFlags();

  if (enabledFeatureFlags.MODELING_FLAG) {
    schedulingLinks.push({
      title: 'Modeling',
      roles: PAGE_ROLES.MODELING,
      path: '/non-union-scheduling/modeling',
    });
  }

  return (
    <>
      <UnauthenticatedTemplate>
        Please Login to see navigation
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navigation aria-label="Main Navigation">
          <Navigation.Item>
            <SideNavLink to="/" onClick={handleLinkClick}>
              <span className="px-4">Home</span>
            </SideNavLink>
          </Navigation.Item>
          {intersection(roles, PAGE_ROLES.NON_UNION_SCHED_MY_SCHEDULE).length >
            0 && (
            <Navigation.Item>
              <SideNavLink
                to={`my-schedule-nu/${uss_id}`}
                onClick={handleLinkClick}
              >
                <span className="px-4">My Schedule (NU)</span>
              </SideNavLink>
            </Navigation.Item>
          )}
          {intersection(roles, PAGE_ROLES.MY_MILEAGE).length > 0 &&
            (user?.plantType === AddPTOPlantType.integratedMill ||
              user?.plantType === AddPTOPlantType.office) && (
              <Navigation.Item>
                <SideNavLink to={`my-mileage`} onClick={handleLinkClick}>
                  <span className="px-4">My Mileage</span>
                </SideNavLink>
              </Navigation.Item>
            )}
          {intersection(roles, PAGE_ROLES.MY_PTO).length > 0 && (
            <Navigation.Item>
              <SideNavLink to={`/my-pto-nu`} onClick={handleLinkClick}>
                <span className="px-4">My PTO (NU)</span>
              </SideNavLink>
            </Navigation.Item>
          )}

          {checkAutherizedMenu(adminLinks) && (
            <Navigation.Dropdown
              label="Admin"
              isOpen={splitLocation[1] === 'admin' ? true : false}
            >
              {adminLinks.map((link, index) => {
                return (
                  <Navigation.Item key={`${link.title}-${index}`}>
                    {intersection(roles, link.roles).length > 0 && (
                      <SideNavLink to={link.path} onClick={handleLinkClick}>
                        <span className="px-6">{link.title}</span>
                      </SideNavLink>
                    )}
                  </Navigation.Item>
                );
              })}
            </Navigation.Dropdown>
          )}

          {checkAutherizedMenu(payLinks) && (
            <Navigation.Dropdown
              label="Pay"
              isOpen={splitLocation[1].includes('pay') ? true : false}
            >
              {payLinks.map((link, index) => {
                return (
                  <Navigation.Item key={`${link.title}-${index}`}>
                    {intersection(roles, link.roles).length > 0 && (
                      <SideNavLink
                        to={link.path}
                        currentselection={link.title}
                        onClick={handleLinkClick}
                      >
                        <span className="px-6">{link.title}</span>
                      </SideNavLink>
                    )}
                  </Navigation.Item>
                );
              })}
            </Navigation.Dropdown>
          )}
          {checkAutherizedMenu(probationaryEmployeesLinks) && (
            <Navigation.Dropdown
              label="Probationary Tracking"
              isOpen={
                splitLocation[1] === 'probationary-tracking' ? true : false
              }
            >
              {probationaryEmployeesLinks.map((link, index) => {
                return (
                  <Navigation.Item key={`${link.title}-${index}`}>
                    {intersection(roles, link.roles).length > 0 && (
                      <SideNavLink
                        to={link.path}
                        currentselection={link.title}
                        onClick={handleLinkClick}
                      >
                        <span className="pl-6">{link.title}</span>
                      </SideNavLink>
                    )}
                  </Navigation.Item>
                );
              })}
            </Navigation.Dropdown>
          )}
          {checkAutherizedMenu(paidTimeOffLinks) && (
            <Navigation.Dropdown
              label="PTO - Vacation"
              isOpen={splitLocation[1] === 'pto' ? true : false}
            >
              {paidTimeOffLinks.map((link, index) => (
                <Navigation.Item key={`${link.title}-${index}`}>
                  {intersection(roles, link.roles).length > 0 && (
                    <SideNavLink
                      to={link.path}
                      currentselection={link.title}
                      onClick={handleLinkClick}
                    >
                      <span className="px-6">{link.title}</span>
                    </SideNavLink>
                  )}
                </Navigation.Item>
              ))}
            </Navigation.Dropdown>
          )}

          {checkAutherizedMenu(schedulingLinks) && (
            <Navigation.Dropdown
              label="Scheduling"
              isOpen={splitLocation[1].includes('scheduling') ? true : false}
            >
              {schedulingLinks.map((link, index) => {
                return (
                  <Navigation.Item key={`${link.title}-${index}`}>
                    {intersection(roles, link.roles).length > 0 && (
                      <SideNavLink
                        to={link.path}
                        currentselection={link.title}
                        onClick={handleLinkClick}
                      >
                        <span className="px-6">{link.title}</span>
                      </SideNavLink>
                    )}
                  </Navigation.Item>
                );
              })}
            </Navigation.Dropdown>
          )}
          {checkAutherizedMenu(refereceLinks) && (
            <Navigation.Dropdown
              label="Reference Links"
              isOpen={splitLocation[1] === 'reference-links' ? true : false}
            >
              {refereceLinks.map((link, index) => {
                return (
                  <Navigation.Item key={`${link.title}-${index}`}>
                    {intersection(roles, link.roles).length > 0 && (
                      <SideNavLink
                        to={link.path}
                        onClick={handleLinkClick}
                        external={link.external}
                      >
                        <span className="pl-6 pr-2">{link.title}</span>
                      </SideNavLink>
                    )}
                  </Navigation.Item>
                );
              })}
            </Navigation.Dropdown>
          )}

          <Navigation.Item>
            <SideNavLink to="logout" onClick={wrapAsyncFunction(azureLogout)}>
              <span className="px-4">Logout</span>
            </SideNavLink>
          </Navigation.Item>
        </Navigation>
      </AuthenticatedTemplate>
    </>
  );
};
export default SideNav;
