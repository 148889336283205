import { Org } from 'features/org';
import { SortDirection } from 'types/common';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ModelFilterType, ModelListParams, ModelOwner, ModelStatus, SortOptionType } from '../types';

export interface ModelDrawerFilters {
  selectedPlant: Org | null;
  selectedPayrollLocation: Org | null;
  selectedModelOwner: ModelOwner | null;
  selectedStatus: ModelStatus | null;
}

interface ModelListFilters {
  params: ModelListParams;
  selectedModelType: ModelFilterType | null;
  selectedPlant: Org | null;
  selectedPayrollLocation: Org | null;
  selectedModelOwner: ModelOwner | null;
  selectedStatus: ModelStatus | null;
  modelOwnersArr: ModelOwner[];
  isFilterApplied: boolean;
  setSort: (value: SortOptionType ) => void;
  clearPlant: () => void;
  clearPayrollLocation: () => void;
  clearModelOwner: () => void;
  clearStatus: () => void;
  setModelType: (value: ModelFilterType) => void;
  setSearch: (value: string) => void;
  setClearSearch: () => void;
  setModelOwnersArr: (value: ModelOwner[]) => void;
  applyFilter: (value: ModelDrawerFilters, params: ModelListParams) => void;
  resetFilters: () => void;
  setIsFilterApplied: (val: boolean) => void;
}

export const useModelListFiltersStore = create<ModelListFilters>()(
  devtools(
    immer((set, get) => ({
      params: {
        sortBy: 'modelName',
        sortType: SortDirection.ASC,
      },
      selectedPlant: null,
      selectedPayrollLocation: null,
      selectedModelOwner: null,
      selectedStatus: null,
      selectedModelType: null,
      modelOwnersArr: [],
      isFilterApplied: false,
      clearPlant: () =>
        set(
          (state) => {
            state.selectedPlant = null;
            state.selectedPayrollLocation = null;
            delete state.params.plant;
            delete state.params.payrollLocation;
          },
          false,
          'update-plant'
        ),
      clearPayrollLocation: () =>
        set(
          (state) => {
            state.selectedPayrollLocation = null;
            delete state.params.payrollLocation;
          },
          false,
          'update-location'
        ),
      clearModelOwner() {
        set(
          (state) => {
            state.selectedModelOwner = null;
            delete state.params.modelOwner;
          },
          false,
          'update-model-owner'
        );
      },
      clearStatus() {
        set(
          (state) => {
            state.selectedStatus = null;
            delete state.params.modelStatus;
          },
          false,
          'update-model-status'
        );
      },
      setModelType(value) {
        set(
          (state) => {
            if (value !== 'all') {
              state.selectedModelType = value;
              state.params.modelType = value;
            } else {
              delete state.params.modelType;
              state.selectedModelType = null;
            }
          },
          false,
          'update-model-status'
        );
      },
      setSort: (value) =>
        set(
          (state) => {
            state.params.sortBy = value.name
            state.params.sortType = value.direction
          },
          false,
          'set-model-sort'
        ),
      setSearch: (value) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.modelName = value;
            } else {
              delete state.params.modelName;
            }
          },
          false,
          'set-model-search'
        ),
      setClearSearch: () =>
        set(
          (state) => {
            delete state.params.modelName;
          },
          false,
          'set-model-clearsearch'
        ),
      setModelOwnersArr: (value) =>
        set((state) => {
          state.modelOwnersArr = value;
        }),
      applyFilter: (value, params) =>
        set((state) => {
          //params
          state.params = params;
          //dropdown fields values
          state.selectedPlant = value.selectedPlant;
          state.selectedPayrollLocation = value.selectedPayrollLocation;
          state.selectedModelOwner = value.selectedModelOwner;
          state.selectedStatus = value.selectedStatus;
        }),
      resetFilters: () =>
        set((state) => {
          delete state.params.plant;
          delete state.params.payrollLocation;
          delete state.params.modelOwner;
          delete state.params.modelStatus;
          state.selectedPlant = null;
          state.selectedPayrollLocation = null;
          state.selectedModelOwner = null;
          state.selectedStatus = null;
        }),
      setIsFilterApplied: (value) =>
        set((state) => {
          state.isFilterApplied = value;
        })
    }))
  )
);
