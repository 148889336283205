import Page from 'components/Layouts/Page';
import { useModelDetails } from 'features/model-listing/api/queries';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Detailscard } from 'features/model-listing/components/ModelDetails/ModelDetailsCard';
import { EditModelDetails } from 'features/model-listing/components/ModelEdit/EditModelDetails';
import { Alert } from '@uss/react-core';
import { Button } from '@uss/react-components';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { WeekCardLoader } from 'features/model-listing/components/ModelDetails/WeekCardLoader';
import CardsLoader from 'components/Loaders/CardsLoader';

export const EditModel = () => {
  const { id = '' } = useParams<'id'>();
  const { data, status } = useModelDetails(id);

  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="bc-1" to="/scheduling">
            Scheduling
          </NavLink>,
          <NavLink key="bc-2" to="/non-union-scheduling/modeling">
            Modeling
          </NavLink>,
        ]}
        heading={'Edit Model Details'}
      >
        <Page.Section>
          <div className="flex gap-5">
            <div className="flex-grow">
              <Alert
                variant="info"
                message="You have checked out this model. Click on Save & Publish to check the model back in to Active status."
              ></Alert>
            </div>
            <div className="flex flex-nowrap gap-4">
              <Button
                variant="text"
                className="text-sm  text-red-600 cursor-pointer"
              >
                <BsTrash id="delete" className="text-sm" />
                <span className=" ml-2">Week(s)/Crew(s)</span>
              </Button>
              <Button
                variant="text"
                className="text-sm text-primary-400 cursor-pointer"
              >
                <BsPlus size={'1.5rem'} className="text-sm" />
                <span>Week(s)/Crew(s)</span>
              </Button>
            </div>
          </div>
        </Page.Section>
        <Page.Section>
          {status === 'success' ? (
            <Detailscard data={data} />
          ) : (
            <CardsLoader variant="row-grid" count={1} hasAvatar />
          )}
        </Page.Section>
        <Page.Section>
          {status === 'success' ? (
            <EditModelDetails data={data} />
          ) : (
            <WeekCardLoader
              numberOfWeeks={9}
              numberOfCrewRotations={2}
              numberOfGridCols={2}
            />
          )}
        </Page.Section>
      </Page>
    </>
  );
};
