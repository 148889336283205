/**
 *
 * @returns Checks if feature flags are enabled/disabled (true/false).
 * @example
 * const { enabledFeatureFlags } = useEnableFeatureFlags();
 */

const useEnableFeatureFlags = () => {
  /**
   * Set Feature flags true/false to show/hide specific app functionality based on environments.
   * @example
   * {
      FLAG_1: false,
      FLAG_1: true,
    };
   */
  const FeatureFlags = {
    MODELING_FLAG: false,
  };

  const hostname = window.location.hostname;

  const setFeatureFlags = (host: string) => {
    switch (host) {
      case 'cappsu.psc.uss.com':
        return {
          ...FeatureFlags,
          MODELING_FLAG: false,
        };
      case 'cappsd.psc.uss.com':
      case 'localhost':
      case 'cappsq.psc.uss.com':
        return {
          ...FeatureFlags,
          MODELING_FLAG: true,
        };
      default:
        return {
          ...FeatureFlags,
          MODELING_FLAG: false,
        };
    }
  };

  const enabledFeatureFlags = setFeatureFlags(hostname);

  return {
    enabledFeatureFlags,
  };
};

export default useEnableFeatureFlags;
