import { Accordion } from '@uss/react-components';
import LabeledText from 'components/Typography/LabeledText';
import { ModelDetailProps } from './ModelDetailTab';
import { ModelStatusCard } from '../Cards/ModelStatusCard';

export const Detailscard = ({ data }: ModelDetailProps) => {
  const DetailsCardHeader = () => {
    return (
      <div className="sm:flex items-center w-full h-full ">
        <div className="flex flex-wrap justify-between items-center lg:flex-nowrap w-full lg:w-full lg:pl-1 lg:pr-5">
          <ModelStatusCard record={data} />
          <div className="flex flex-grow w-full">
            <LabeledText
              label="Payroll Location"
              lineClamp={1}
              className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0 sm:pl-4"
            >
              {data.payrollLocationCode ? data.payrollLocationCode : '-'}
            </LabeledText>
            <LabeledText
              label="Weeks"
              lineClamp={1}
              className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0 sm:pl-4"
            >
              {data.totalWeeks ? data.totalWeeks : '-'}
            </LabeledText>

            <LabeledText
              label="Crew Rotations"
              lineClamp={1}
              className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0 sm:pl-4"
            >
              {data.totalCrewRotations ? data.totalCrewRotations : '-'}
            </LabeledText>
          </div>
        </div>
      </div>
    );
  };
  const DetailsCardBody = () => {
    return (
      <div
        id="model-detail-card"
        className=" w-full  mt-2 flex flex-col justify-center mb-4 mx-4"
      >
        <div className="flex flex-wrap justify-between items-center lg:flex-nowrap w-full lg:w-full lg:pl-1 lg:pr-5 mb-4">
          <LabeledText
            label="Model Owner"
            lineClamp={1}
            className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {data.owner.displayName ? data.owner.displayName : '-'}
          </LabeledText>
          <LabeledText
            label="Created On"
            lineClamp={1}
            className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {data.createdOn ? data.createdOn : '-'}
          </LabeledText>
          <LabeledText
            label="Last Updated By"
            lineClamp={1}
            className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {data.lastUpdatedBy ? data.lastUpdatedBy : '-'}
          </LabeledText>

          <LabeledText
            label="Last Updated On"
            lineClamp={1}
            className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {data.lastUpdatedOn ? data.lastUpdatedOn : '-'}
          </LabeledText>

          <LabeledText
            label="Last Used By"
            lineClamp={1}
            className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {data.lastUsedBy ? data.lastUsedBy : '-'}
          </LabeledText>

          <LabeledText
            label="Last Used On"
            lineClamp={1}
            className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {data.lastUsedOn ? data.lastUsedOn : '-'}
          </LabeledText>
        </div>
        <div className="text-gray-700 text-xs text-left">Model Description</div>
        <div className=" pt-2 pb-5 px-2 h-auto  rounded-md border border-gray-600 ">
          {data.modelDescription}
        </div>
        <div className=" text-right mr-6 text-gray-600">
          {`${data.modelDescription.length}/100`}
        </div>
      </div>
    );
  };
  return (
    <>
      <Accordion
        variant="default"
        className="shadow-md bg-white rounded-lg border border-blue-600 "
      >
        <Accordion.Button variant="default">
          <DetailsCardHeader />
        </Accordion.Button>
        <Accordion.Panel
          variant="default"
          className="flex flex-row flex-wrap items-center border-t border-gray-200 pt-4"
        >
          <DetailsCardBody />
        </Accordion.Panel>
      </Accordion>
    </>
  );
};
