import { PayPeriodStatus } from 'features/pay-periods/types';
import getFormattedPayPeriodStatus from 'features/timecards/utilities/getFormattedPayPeriodStatus';

interface CrewDetailsProps {
  crewKey: string | undefined;
  crewDescription: string | undefined;
  totalCount: number | undefined;
  payPeriodStatus: PayPeriodStatus | undefined;
  primaryApprover: string | null | undefined;
  plantType?: string | null;
}

const CrewDetails = ({
  crewKey,
  crewDescription,
  totalCount,
  payPeriodStatus,
  primaryApprover,
  plantType,
}: CrewDetailsProps) => {
  return (
    <div
      className="flex flex-wrap sm:flex-nowrap w-full justify-between bg-blue-50 rounded px-4 py-3 h-auto "
      data-testid="crew-details"
    >
      <div className="flex flex-row justify-start items-center mb-4 sm:mb-0 w-2/4 sm:w-1/6">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600 ">Crew</span>
          <span className="text-sm line-clamp-1">
            {crewKey ? crewKey : '-'}
          </span>
        </span>
      </div>
      <div className="flex flex-row justify-start items-center mb-4 sm:mb-0 w-2/4 sm:w-1/6">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600">Crew Description</span>
          <span className="text-sm line-clamp-1">
            {crewDescription ? crewDescription : '-'}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start items-center mb-4 sm:mb-0 w-2/4 sm:w-1/6">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600">Primary Approver</span>
          <span className="text-sm line-clamp-1">
            {primaryApprover ? primaryApprover : '-'}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start items-center mb-4 sm:mb-0 w-2/4 sm:w-1/6">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600">Crew Member Count</span>
          <span className="text-sm line-clamp-1">
            {totalCount ? totalCount : '-'}
          </span>
        </span>
      </div>
      {plantType !== 'integrated_mill' && (
        <div className="flex flex-row justify-start items-center  w-2/4 sm:w-1/6">
          <span className="flex flex-col justify-start">
            <span className="text-xs text-gray-600">Pay period Status</span>
            <span className="text-sm line-clamp-1">
              {payPeriodStatus
                ? getFormattedPayPeriodStatus(payPeriodStatus)
                : '-'}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default CrewDetails;
