import React from 'react';
import { Avatar } from '@uss/react-components';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { avatarColor, getInitials } from 'utils';
import { MileageActions } from './MileageActions';
import useWindowSize from 'hooks/useWindowSize';
import { MileageDetailsResponse } from '../types/mileageEntry';
import { format } from 'date-fns';

interface MileageDetailsProps {
  mileageDetails: MileageDetailsResponse;
}

export const MileageApprovalCard = ({
  mileageDetails,
}: MileageDetailsProps) => {
  const { width } = useWindowSize();
  return (
    <>
      {mileageDetails.items.map((details, index) => {
        return (
          <div key={index} className="mb-4">
            <Card>
              <Card.Header>
                <div className="flex w-full justify-between">
                  <div className="flex flex-wrap w-full">
                    <div className="flex items-center w-full sm:w-3/12">
                      <Avatar color={avatarColor(99100)} size="medium">
                        {getInitials(details.firstName, details.lastName)}
                      </Avatar>
                      <div className="pl-3 flex flex-wrap ">
                        <Title as="h2">{details.displayName}</Title>
                      </div>
                    </div>
                    <div className="flex w-full gap-4 sm:gap-10 sm:w-3/5 mt-1 sm:mt-0">
                      <LabeledText
                        label="USS ID"
                        lineClamp={1}
                        className="w-2/4 sm:w-1/5"
                      >
                        {details.ussId}
                      </LabeledText>

                      <LabeledText
                        label="Payroll No"
                        lineClamp={1}
                        className="w-2/4 sm:ml-12 sm:w-1/5"
                      >
                        {details.payrollNumber}
                      </LabeledText>
                    </div>
                  </div>
                </div>
                <MileageActions inHeader mileageDetails={details} />
              </Card.Header>
              <Card.Body>
                <div className="flex flex-wrap sm:flex-nowrap mr-0 sm:mr-16">
                  <LabeledText
                    label="Payroll Location"
                    lineClamp={1}
                    className="w-2/4 sm:w-3/12 mb-1"
                  >
                    {details.payrollLocation}
                  </LabeledText>

                  <LabeledText
                    label="Miles"
                    lineClamp={1}
                    className="w-2/4 sm:w-1/5 mb-1"
                  >
                    {details.miles}
                  </LabeledText>
                  <LabeledText
                    label="Travelled On"
                    lineClamp={1}
                    className="w-2/4 sm:w-1/5 mb-1"
                  >
                    {format(new Date(details.travelDate), 'MMM dd, yyyy')}
                  </LabeledText>

                  <LabeledText
                    label="Requested On"
                    lineClamp={1}
                    className="w-2/4 sm:w-1/5 mb-1"
                  >
                    {format(new Date(details.requestedDate), 'MMM dd, yyyy')}
                  </LabeledText>

                  <LabeledText
                    label="Status"
                    lineClamp={1}
                    className="w-2/4 sm:w-1/5 mb-1"
                  >
                    {details.status
                      ? details.status[0].toLocaleUpperCase() +
                        details.status.slice(1)
                      : '-'}
                  </LabeledText>
                </div>
              </Card.Body>
              {width && width < 780 && (
                <Card.Footer>
                  <MileageActions inFooter mileageDetails={details} />
                </Card.Footer>
              )}
            </Card>
          </div>
        );
      })}
    </>
  );
};
