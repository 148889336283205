import { Button, UnstyledLink } from '@uss/react-core';
import { MODEL_STATUS } from 'features/model-listing/constants';
import { ModelDetailsResponse } from 'features/model-listing/types';
import { getModelActionButton } from 'features/model-listing/utility/getModelActionButton';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { WeekCard } from './WeekCard';
import { NavLink } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
export interface ModelDetailProps {
  data: ModelDetailsResponse;
}
export const ModelDetailTab = ({ data }: ModelDetailProps) => {
  const { roles, uss_id } = useUserRoles();
  const { archiveButton, editButton, copyButton, deleteButton, restoreButton } =
    getModelActionButton({
      data,
      roles,
      ussId: uss_id,
    });
  const isDisabled = () => {
    return roles.includes(ROLES.PAY_APPROVER) &&
      data.owner.ussId.toString() !== uss_id &&
      data.modelStatus !== MODEL_STATUS.ACTIVE
      ? true
      : false;
  };

  return (
    <>
      <div className=" grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-4 gap-4">
        {data.weeks.map((week, index) => {
          return (
            <WeekCard
              weekData={week}
              modelName={data.modelName}
              weekBeginIndex={data.weekBeginIndex}
              totalWeeks={data.totalWeeks}
              key={index}
            />
          );
        })}
      </div>

      <div className="flex justify-end flex-wrap gap-2 mt-5">
        {archiveButton && (
          <Button
            variant="outline"
            color={'primary'}
            type="button"
            isDisabled={isDisabled()}
          >
            Archive
          </Button>
        )}

        {deleteButton && (
          <Button
            variant="outline"
            color="danger"
            type="button"
            isDisabled={isDisabled()}
          >
            Delete
          </Button>
        )}
        {restoreButton && (
          <Button
            variant="outline"
            color={'primary'}
            type="button"
            isDisabled={isDisabled()}
          >
            Restore
          </Button>
        )}

        {copyButton && (
          <Button
            variant="filled"
            color={'primary'}
            type="button"
            isDisabled={isDisabled()}
          >
            Copy
          </Button>
        )}
        {editButton && (
          <>
            <UnstyledLink
              as={NavLink}
              className="flex justify-center items-center relative rounded outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-secondary-500 text-white min-w-[128px] px-[15px] disabled:bg-gray-300 disabled:text-gray-500 text-base py-[9px] leading-4 bg-brand border hover:bg-brand-hover hover:border-brand-hover disabled:border-gray-300"
              to={`edit-model`}
            >
              Edit
            </UnstyledLink>
          </>
        )}
      </div>
    </>
  );
};
