import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Select, TextArea } from '@uss/react-components';
import useModal from 'components/Modals/use-modal';
import { Paycode, PaycodeStatus } from 'features/paycode';
import {
  PaycodeUpdateBody,
  PaycodeUpdateBodySchema,
  useUpdatePaycode,
} from 'features/paycode/api';
import ArchiveRestoreModal from 'features/paycode/ArchiveRestoreModal';
import { PAYCODE_STATUS, PAYCODE_TYPE } from 'features/paycode/constants';
import getPaycodeTypeText from 'features/paycode/utilities/getPaycodeTypeText';
import { validateForm } from 'features/paycode/utilities/validateForm';
import { ROLES } from 'features/roles';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { wrapAsyncFunction } from 'utils';
import { PayrollGroupCard } from './PayrollGroupCard';

export type PaycodeActionButton =
  | 'create'
  | 'request'
  | 'archive'
  | 'restore'
  | 'save'
  | 'approve'
  | 'deny';

interface Props {
  id: string;
  isSuperAdmin: boolean;
  enableCodeType: boolean;
  paycode?: Paycode;
  roles: string[];
  buttonState: {
    canSave: boolean;
    canApprove: boolean;
    canArchive: boolean;
    canRestore: boolean;
    canReject: boolean;
  };
}
export const PaycodeForm = ({
  id,
  paycode,
  isSuperAdmin,
  roles,
  buttonState,
  enableCodeType = false,
}: Props) => {
  const buttonRef = useRef<PaycodeActionButton>('save');
  const modal = useModal();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Paycode>({
    resolver: zodResolver(PaycodeUpdateBodySchema),
    defaultValues: { ...paycode, comments: '' },
  });
  const useCaseDescription = useWatch({ control, name: 'useCaseDescription' });
  const comments = useWatch({ control, name: 'comments' });
  const payrollGroup = useWatch({ control, name: `payrollGroups` });
  const [enableValidation, setEnableValidation] = useState(false);

  useEffect(() => {
    if (enableValidation) {
      clearErrors();
      validateForm(data, PaycodeUpdateBodySchema, setError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollGroup]);

  const data = getValues();
  const handleCancel = () => {
    navigate('/admin/paycodes');
  };

  //single validation message
  const ValidationMsg = ({
    msg,
    name,
  }: {
    msg: string | undefined;
    name: string;
  }) => {
    return (
      <p className="text-sm text-red-600" data-testid={`validation-` + name}>
        {msg}
      </p>
    );
  };
  //single validation message
  const ValidationSummary = () => {
    return (
      <>
        {Object.entries(errors).map(([key, values]) => {
          return (
            <div key={key}>
              {(key === 'payableOvertimeCheck' ||
                key === 'reportableOvertimeCheck' ||
                key === 'payrollGroupRequiredCheck' ||
                key === 'shiftAssignmentRequiredCheck') && (
                <ValidationMsg msg={values.message} name={key.toString()} />
              )}
            </div>
          );
        })}
      </>
    );
  };

  const SaveAction = (type: PaycodeActionButton) => {
    if (id === '0') {
      return isSuperAdmin ? 'create' : 'request';
    } else {
      return type;
    }
  };
  const SaveLabel = () => {
    if (id === '0') {
      return isSuperAdmin ? 'Add' : 'Request';
    } else {
      return 'Save';
    }
  };
  const getModalTitle = (code: PaycodeStatus): string => {
    if (code === PAYCODE_STATUS.ARCHIVED) {
      return 'Restore Pay Code!';
    } else if (code === PAYCODE_STATUS.ACTIVE) {
      return 'Archive Pay Code!';
    } else {
      return 'Approve Pay Code!';
    }
  };
  const getActionStatus = (): PaycodeStatus => {
    if (buttonRef.current === 'approve') {
      return PAYCODE_STATUS.ACTIVE;
    }
    if (buttonRef.current === 'request') {
      return PAYCODE_STATUS.PENDING;
    } else if (buttonRef.current === 'deny') {
      return PAYCODE_STATUS.DENIED;
    } else {
      return PAYCODE_STATUS.ACTIVE;
    }
  };

  //update paycode api hook
  const { mutateAsync: editPaycodeRecord } = useUpdatePaycode(
    typeof data !== 'undefined' ? data.id : '',
    'save'
  );
  const handleAdd = async () => {
    const data = getValues();
    const code: PaycodeStatus = getActionStatus();
    data.status = {
      ...data.status,
      code: code,
    };
    if (id === '0') {
      const payload = { ...data, id: null };

      await editPaycodeRecord(payload as PaycodeUpdateBody);
    }
  };

  const handleUpdate = async () => {
    const data = getValues();
    const code: PaycodeStatus = getActionStatus();
    modal.closeModal();
    data.status = {
      ...data.status,
      code: code,
    };
    if (id !== '0') {
      await editPaycodeRecord(data as PaycodeUpdateBody);
    }
  };
  const getText = (type: string, action: PaycodeActionButton) => {
    if (type === 'title') {
      if (action === 'approve') {
        return 'Approve Pay Code!';
      } else if (action === 'deny') {
        return 'Deny Pay Code!';
      } else {
        return 'Save Changes!';
      }
    }
    if (type === 'msg') {
      if (action === 'approve') {
        return 'Are you sure you want to approve this pay code?';
      } else if (action === 'deny') {
        return 'Are you sure you want to deny this pay code?';
      } else {
        return 'Are you sure you want to save the changes?';
      }
    } else if (type === 'action') {
      if (action === 'approve') {
        return 'Approve';
      } else if (action === 'deny') {
        return 'Deny';
      } else {
        return 'Save';
      }
    }
    return '';
  };
  const PaycodeConfirmMsg = () => {
    return (
      <p className="text-sm text-gray-700">
        {getText('msg', buttonRef.current)}
      </p>
    );
  };
  const openConfirmModal = (action: PaycodeActionButton) => {
    modal.openModal({
      title: getText('title', action),
      type: 'danger',
      children: <PaycodeConfirmMsg />,
      labels: {
        confirm: getText('action', action),
        cancel: 'Cancel',
      },
      onCancel: () => modal.closeModal(),
      onConfirm: wrapAsyncFunction(handleUpdate),
    });
  };
  const handleArchiveRestore = (): void => {
    const code = paycode?.status.code;
    (code === PAYCODE_STATUS.ACTIVE ||
      code === PAYCODE_STATUS.ARCHIVED ||
      code === PAYCODE_STATUS.DENIED ||
      code === PAYCODE_STATUS.PENDING) &&
      modal.openModal({
        title: getModalTitle(code),
        type: 'regular',
        size: 'md',
        children: (
          <ArchiveRestoreModal
            paycodeId={id}
            code={code}
            action={buttonRef.current}
            closeModal={modal.closeModal}
            handleUpdate={handleUpdate}
          />
        ),
      });
  };
  const handleValidateForm = () => {
    if (
      (roles.includes(ROLES.SUPER_ADMIN) ||
        roles.includes(ROLES.SUPER_PAYROLL_ADMIN)) &&
      data.status.code === 'denied'
    ) {
      return true;
    } else {
      return validateForm(data, PaycodeUpdateBodySchema, setError);
    }
  };

  //save button
  const handleConfirm = (type: PaycodeActionButton) => {
    setEnableValidation(true);
    if (handleValidateForm()) {
      buttonRef.current = SaveAction(type);
      if (type === 'approve') {
        handleArchiveRestore();
      } else if (type !== 'create') {
        openConfirmModal(SaveAction(type));
      }
    } else {
      return;
    }
  };

  return (
    <>
      {
        <form className="" onSubmit={handleSubmit(handleAdd)}>
          <div className="bg-white pt-4 sm:bg-white rounded-md mb-2 pb-4 px-3">
            <div className="w-full lg:w-4/5 lg:m-auto ">
              <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-5 w-full">
                <div className="mb-4 lg:mb-0">
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="text"
                        id="code"
                        label="Code"
                        disabled={!enableCodeType}
                        error={!!errors.code?.message}
                        errorMessage={errors.code?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="mb-4 lg:mb-0">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="text"
                        id="description"
                        label="Description"
                        error={!!errors.description?.message}
                        errorMessage={errors.description?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="mb-4 lg:mb-0">
                  <span className="text-sm text-gray-600">Type</span>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="type"
                    render={({
                      field: { value, onChange },
                      fieldState: { invalid },
                    }) => (
                      <>
                        <Select
                          selected={value}
                          onSelect={onChange}
                          disabled={!enableCodeType}
                        >
                          <Select.Button
                            label={value ? value : 'Select'}
                            error={invalid}
                          />
                          <Select.Options error={invalid}>
                            {Object.values(PAYCODE_TYPE).map(
                              (item, itemIdx) => (
                                <Select.Option key={itemIdx} value={item}>
                                  <span>{getPaycodeTypeText(item)}</span>
                                </Select.Option>
                              )
                            )}
                          </Select.Options>
                        </Select>
                        {invalid && (
                          <ValidationMsg
                            msg="Paycode type must be selected"
                            name="paycodeRequired"
                          />
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="col-span-2 mb-4 lg:mb-0">
                  <TextArea
                    maxLength={500}
                    label="Use Case Description"
                    id="form-comments"
                    data-testid="input-useCaseDescription"
                    placeholder={
                      'Describe in details when to use this pay code'
                    }
                    {...register('useCaseDescription')}
                    error={!!errors.useCaseDescription?.message}
                    errorMessage={errors.useCaseDescription?.message}
                  />
                  <div className="col-span-2 text-xs mt-2 text-gray-600 text-right">
                    {500 - useCaseDescription.length} Characters left
                  </div>
                </div>

                <div className="col-span-2 mb-4 lg:mb-0">
                  <p className="text-gray-700 text-xs mb-1">
                    Pay Groups (Select applicable Group(s) and respective
                    Payroll Indicators for this pay code)
                  </p>

                  {paycode?.payrollGroups != null
                    ? paycode.payrollGroups.map((payrollGroup, index) => {
                        return (
                          <Controller
                            key={index}
                            name="payrollGroups"
                            control={control}
                            render={() => (
                              <PayrollGroupCard
                                key={index}
                                index={index}
                                data={payrollGroup}
                                setValue={setValue}
                              />
                            )}
                          />
                        );
                      })
                    : ''}
                  <ValidationSummary />
                </div>

                <div className="col-span-2 mb-4 lg:mb-0">
                  <TextArea
                    maxLength={100}
                    label="Comment"
                    data-testid="comment-textaria"
                    id="form-comments"
                    {...register('comments')}
                    placeholder={'Describe reason to add this paycode'}
                    error={!!errors.comments?.message}
                    errorMessage={errors.comments?.message}
                  />
                  <div className="col-span-2 text-xs mt-2 text-gray-600 text-right">
                    {100 - comments.length} Characters left
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end flex-wrap mt-4">
            <Button
              onClick={handleCancel}
              variant="outline"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="button"
              data-testid="cancel-button"
            >
              Cancel
            </Button>

            {buttonState.canArchive && (
              <Button
                onClick={() => {
                  buttonRef.current = 'archive';
                  handleArchiveRestore();
                }}
                variant="outline"
                className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
                type="button"
                data-testid="archive-button"
              >
                Archive
              </Button>
            )}

            {buttonState.canSave && (
              <Button
                onClick={() => {
                  handleConfirm(id === '0' ? 'create' : 'save');
                }}
                variant="primary"
                className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
                type="submit"
                data-testid="save-button"
              >
                {SaveLabel()}
              </Button>
            )}
            {buttonState.canReject && (
              <Button
                onClick={() => {
                  handleConfirm('deny');
                }}
                variant="primary"
                className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
                type="submit"
                data-testid="deny-button"
              >
                Deny
              </Button>
            )}

            {buttonState.canApprove && (
              <Button
                onClick={() => {
                  handleConfirm('approve');
                }}
                variant="primary"
                className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
                type="submit"
                data-testid="approve-button"
              >
                Approve
              </Button>
            )}
          </div>
        </form>
      }
    </>
  );
};
