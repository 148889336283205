import { Link, Checkbox } from '@uss/react-components';
import clsx from 'clsx';
import NoResults from 'components/NoResults/NoResults';
import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';
import React from 'react';
import { BsExclamationCircle, BsExclamationTriangle } from 'react-icons/bs';
import { CrewTimeCard } from 'features/timecards/types';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import CrewListingCardLoader from './Loaders/CrewListingCardLoader';
import isEmployeeSelected from 'features/timecards/utilities/checkEmployeeTimecardSelection';
import getFormattedCrewFilterStatus from 'features/timecards/utilities/getFormattedCrewFilterStatus';
import getNoPayEmployees from 'features/timecards/utilities/getNoPayEmployees';
interface Props {
  employees: CrewTimeCard[];
  isLoadingTimecards: boolean;
  handleRowClick: (id: number, target: Element) => void;
  allActive: boolean;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  employeeErrors: string[];
  payPeriodStatus: PayPeriodStatus | undefined;
  rowSelect?: boolean;
  plantType?: string | null;
}

const EmployeeCards = ({
  employees,
  isLoadingTimecards,
  handleRowClick,
  allActive,
  handleOnChange,
  employeeErrors,
  payPeriodStatus,
  rowSelect = false,
  plantType,
}: Props) => {
  const state = useNonUnionStore();
  const noPayEmployees = getNoPayEmployees(employees);

  const getCardClasses = (id: string): string => {
    if (employeeErrors.includes(id)) {
      return 'border border-red-500 bg-white ';
    } else if (isEmployeeSelected(state.selectedEmployees, id)) {
      return 'border bg-blue-100 ';
    } else return '';
  };

  const isMassApprovalEnabled =
    rowSelect &&
    employees.length > 0 &&
    !!employees.find((ctc) => ctc.payPeriodStatus === PAY_PERIOD_STATUS.OPEN);

  if (isLoadingTimecards) {
    return <CrewListingCardLoader />;
  } else if (employees.length === 0) {
    return (
      <div>
        <NoResults width={'300'} height={'300'} />
      </div>
    );
  } else {
    return (
      <>
        <div data-testid="employee-cards-container">
          {isMassApprovalEnabled && (
            <div className="flex items-center mb-4 border-b">
              <span className="w-1/12">
                <Checkbox
                  label="Checkbox All"
                  name="Checkbox all"
                  id="select-all"
                  hideLabel
                  checked={
                    allActive ||
                    (state.selectedEmployees.length === employees.length &&
                      employees.length !== 0)
                  }
                  onChange={handleOnChange}
                />

                <label className="sr-only" htmlFor="select-all">
                  Select
                </label>
              </span>
              <span>Select All</span>
            </div>
          )}
          {employees.map((ctc) => {
            return (
              <Link
                as="a"
                className={clsx(
                  'px-3 py-1 text-lg  my-4 last-of-type:my-0 border-b block cursor-pointer',
                  getCardClasses(ctc.id)
                )}
                key={ctc.ussId}
                onClick={(e: React.SyntheticEvent) => {
                  const target = e.target as Element;
                  handleRowClick(ctc.ussId, target);
                }}
              >
                <div className="mb-2 w-12/12">
                  <div className="grid grid-cols-10">
                    {isMassApprovalEnabled && (
                      <div className="flex flex-col justify-start">
                        {(plantType !== 'integrated_mill' ||
                          ctc.payPeriodStatus === PAY_PERIOD_STATUS.OPEN) && (
                          <span>
                            <Checkbox
                              label={ctc.id}
                              name={ctc.id}
                              id={ctc.id}
                              hideLabel
                              checked={isEmployeeSelected(
                                state.selectedEmployees,
                                ctc.id
                              )}
                              onChange={handleOnChange}
                            />

                            <label className="sr-only" htmlFor="select-all">
                              Select
                            </label>
                          </span>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col justify-start col-span-8 gap-y-4">
                      <span className="line-clamp-1">{ctc.name}</span>
                      <div className="grid grid-cols-2  mb-2">
                        <div className="flex flex-col justify-start">
                          <span className="text-xs text-gray-600 ">USS ID</span>
                          <span className="text-sm">{ctc.ussId}</span>
                        </div>
                        <div className="flex flex-col justify-start">
                          <span className="text-xs text-gray-600 ">Status</span>
                          <span className="text-sm">
                            {ctc.status &&
                              getFormattedCrewFilterStatus(ctc.status)}
                          </span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="text-sm text-gray-600 font-semibold">
                          Total Hours
                        </div>
                        <div className="grid grid-cols-2 gap-y-4">
                          <div className="flex flex-col justify-start">
                            <span className="text-xs text-gray-600 ">
                              Scheduled
                            </span>
                            <span className="text-sm">
                              {ctc.totalHours.scheduled}
                            </span>
                          </div>
                          <div className="flex flex-col justify-start">
                            <span className="text-xs text-gray-600 ">
                              Worked
                            </span>
                            <span className="text-sm">
                              {ctc.totalHours.worked}
                            </span>
                          </div>
                          <div className="flex flex-col justify-start">
                            <span className="text-xs text-gray-600 ">
                              Approved
                            </span>
                            <span className="text-sm">
                              {ctc.totalHours.approved}
                            </span>
                          </div>
                          <div className="flex flex-col justify-start">
                            <span className="text-xs text-gray-600 ">
                              Paid Not Worked
                            </span>
                            <span className="text-sm">
                              {ctc.totalHours.paidHoursNotWorked}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-start">
                      <div className="mt-1 ml-3">
                        {payPeriodStatus === 'open' &&
                          (noPayEmployees.length > 0 ||
                            employeeErrors.length > 0) && (
                            <>
                              {noPayEmployees.includes(ctc) && (
                                <BsExclamationCircle
                                  className="text-yellow-700 line-clamp-2"
                                  size="1.25rem"
                                  aria-label="Error"
                                ></BsExclamationCircle>
                              )}
                              {employeeErrors.includes(ctc.id) && (
                                <BsExclamationTriangle
                                  color="red"
                                  size="1.25rem"
                                  aria-label="Error"
                                ></BsExclamationTriangle>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </>
    );
  }
};
export default EmployeeCards;
