import { useQuery } from '@tanstack/react-query';
import { mileageEntry } from './api';
import { GetMileageQueryParams } from '../types/mileageEntry';

//For My Mileage Screen
export const useMileageDetails = (ussId: string) => {
  return useQuery({
    queryFn: () => mileageEntry.get(ussId),
    queryKey: ['mileage-details'],
  });
};

//For Mileage Approval Screen
export const useMileageApprovalDetails = (
  ussId: string,
  options: GetMileageQueryParams,
  status: string
) => {
  return useQuery({
    queryFn: () =>
      mileageEntry.get(ussId, {
        ...options,
      }),
    queryKey: [`mileage-details`, options],
    enabled: options.status && status === options.status ? true : false,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 0,
  });
};
