import { Button } from '@uss/react-components';
import Error from 'components/Error/Error';
import useModal from 'components/Modals/use-modal';
import ArchiveRestoreModal from 'features/paycode/ArchiveRestoreModal';
import { useEffect, useId, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePaycodeById } from 'features/paycode/api/getPaycodeById';
import { Paycode, PaycodeStatus } from 'features/paycode/types';
import {
  PaycodeUpdateBody,
  PaycodeUpdateBodySchema,
  useUpdatePaycode,
} from 'features/paycode/api/updatePaycode';
import { PaycodeActionButton } from './PaycodeForm';
import { PAYCODE_STATUS } from 'features/paycode/constants';
import getPaycodeTypeText from 'features/paycode/utilities/getPaycodeTypeText';
import getFormattedShiftAssignment from 'features/paycode/utilities/getFormattedShiftAssignment';
import './PaycodeViewForm.css';
import getPayrollIndicatorText from 'features/paycode/utilities/getPayrollIndicatorText';
import CardsLoader from 'components/Loaders/CardsLoader';

interface Props {
  buttonState: {
    canApprove: boolean;
    canRestore: boolean;
  };
}

export const PaycodeViewForm = ({ buttonState }: Props) => {
  const modal = useModal();
  const navigate = useNavigate();
  //get id from params
  const { id = '' } = useParams<'id'>();
  //get selected paycode data
  const { data: paycode, status: paycodeFetchStatus } = usePaycodeById(id);
  const buttonRef = useRef<PaycodeActionButton>('save');

  const handleCancel = () => {
    navigate('/admin/paycodes');
  };

  const [paycodeData, setPaycodeData] = useState<Paycode>();
  const code = paycodeData?.status.code;
  //Reset Initial data when api response is available
  useEffect(() => {
    if (paycodeFetchStatus === 'success') {
      setPaycodeData({ ...paycode, comments: '' });
    }
  }, [paycode, paycodeFetchStatus]);

  const getModalTitle = (code: PaycodeStatus): string => {
    if (code === PAYCODE_STATUS.ARCHIVED) {
      return 'Restore Pay Code!';
    } else {
      return 'Approve Pay Code!';
    }
  };
  const getActionStatus = (): PaycodeStatus => {
    if (buttonRef.current === 'approve') {
      return PAYCODE_STATUS.ACTIVE;
    } else if (buttonRef.current === 'deny') {
      return PAYCODE_STATUS.DENIED;
    } else {
      return PAYCODE_STATUS.ACTIVE;
    }
  };
  const handleApproveRestore = (): void => {
    (code === PAYCODE_STATUS.ARCHIVED || code === PAYCODE_STATUS.DENIED) &&
      modal.openModal({
        title: getModalTitle(code),
        type: 'regular',
        size: 'md',
        children: (
          <ArchiveRestoreModal
            paycodeId={paycodeData ? paycodeData.id : ''}
            code={code}
            action={buttonRef.current}
            closeModal={modal.closeModal}
            handleUpdate={handleUpdate}
          />
        ),
      });
  };
  //update paycode api hook
  const { mutateAsync: editPaycodeRecord } = useUpdatePaycode(
    typeof paycodeData !== 'undefined' ? paycodeData.id : '',
    buttonRef.current
  );
  //generate update payload from form data
  const getUpdatePaycodePayload = (
    paycode: Paycode | undefined
  ): PaycodeUpdateBody => {
    if (paycode?.payrollGroups && paycode.payrollGroups.length > 0) {
      return PaycodeUpdateBodySchema.parse(paycode);
    }
    return {} as PaycodeUpdateBody;
  };
  const handleUpdate = async () => {
    modal.closeModal();
    if (buttonRef.current === 'approve' || buttonRef.current === 'deny') {
      const code: PaycodeStatus = getActionStatus();

      if (paycodeData) {
        paycodeData.status = {
          ...paycodeData.status,
          code: code,
        };
      }
    }

    await editPaycodeRecord(getUpdatePaycodePayload(paycodeData));
  };

  return (
    <>
      {paycodeFetchStatus === 'pending' && <CardsLoader />}
      {paycodeFetchStatus === 'error' && <Error />}
      {paycodeFetchStatus === 'success' && (
        <div className="bg-white sm:bg-white rounded-md">
          <div className="w-full py-2 px-6 lg:pl-40 lg:pr-24">
            <div className="grid grid-cols-2 gap-x-10 gap-y-4 w-full">
              <LabelledValues label={'Code'} value={paycode.code} fontBold />
              <LabelledValues
                label={'Description'}
                value={paycode.description}
                fontBold
              />
              <LabelledValues
                label={'Type'}
                value={getPaycodeTypeText(paycode.type)}
                fontBold
              />

              <LabelledValues
                label={'Use Case Description'}
                value={
                  paycode.useCaseDescription ? paycode.useCaseDescription : '-'
                }
                className="col-span-2"
                fontBold
              />

              <hr className="col-span-2" />
              <div className="col-span-2">
                <span className="text-sm  text-gray-400 col-span-2">
                  Pay Groups
                </span>
                <div className="grid lg:grid-cols-2 gap-x-10 gap-y-6 w-full">
                  {paycode.payrollGroups && paycode.payrollGroups.length > 0
                    ? paycode.payrollGroups.map((item, i) => {
                        return (
                          <span key={i.toString()}>
                            <span
                              className="text-xs	 font-semibold payroll-group-item"
                              aria-label="Payroll Group Name"
                            >
                              <label className="ml-4">{item.name}</label>
                            </span>
                            <span className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-4 mt-2">
                              <span className="w-1/2 sm:w-auto sm:flex-grow  ">
                                <p
                                  className="text-gray-400 text-xs text-left"
                                  id={`payroll-indicators-${i}`}
                                >
                                  Payroll Indicators
                                </p>
                                <p
                                  className="text-gray-900 text-sm text-left font-semibold line-clamp-3"
                                  aria-labelledby={`payroll-indicators-${i}`}
                                >
                                  {item.payrollIndicators.length > 0
                                    ? item.payrollIndicators.map(
                                        (payable, i) =>
                                          getPayrollIndicatorText(
                                            payable.name
                                          ) + `, `
                                      )
                                    : '-'}
                                </p>
                              </span>
                              <span className="w-1/2 sm:w-auto sm:flex-grow  ">
                                <p
                                  className="text-gray-400 text-xs text-left"
                                  id={`shift-assignment-${i}`}
                                >
                                  Shift Assignment
                                </p>
                                <p
                                  className="text-gray-900 text-sm text-left font-semibold"
                                  aria-labelledby={`shift-assignment-${i}`}
                                >
                                  {item.shifts.length > 0
                                    ? item.shifts.map(
                                        (shift, i) =>
                                          getFormattedShiftAssignment(
                                            shift.name
                                          ) + `, `
                                      )
                                    : '-'}
                                </p>
                              </span>
                            </span>
                          </span>
                        );
                      })
                    : '-'}
                </div>
              </div>
              <hr className="col-span-2" />
              <div className="col-span-2 mb-4 lg:mb-0">
                <LabelledValues
                  label={'Comments'}
                  value={paycode.comments ? paycode.comments : ''}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {paycodeFetchStatus === 'success' && paycodeData && (
        <div className="flex justify-end flex-wrap mt-4">
          <Button
            onClick={handleCancel}
            variant="outline"
            className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
            type="button"
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          {buttonState.canRestore && (
            <Button
              onClick={() => {
                buttonRef.current = 'restore';
                handleApproveRestore();
              }}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="button"
              data-testid="restore-button"
            >
              Restore
            </Button>
          )}
          {buttonState.canApprove && (
            <Button
              onClick={() => {
                handleApproveRestore();
              }}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
              data-testid="approve-button"
            >
              Approve
            </Button>
          )}
        </div>
      )}
    </>
  );
};

const LabelledValues = ({
  label,
  value,
  className,
  fontBold,
}: {
  label: string;
  value: string;
  className?: string;
  fontBold?: boolean;
}) => {
  const labelId = useId();
  return (
    <span className={`sm:w-auto sm:flex-grow ${className ?? ''}`}>
      <p className="text-gray-400 text-xs text-left" id={labelId}>
        {label}
      </p>
      <p
        className={`text-gray-900 text-sm text-left  ${
          fontBold ? 'font-semibold' : ''
        } `}
        aria-labelledby={labelId}
      >
        {value}
      </p>
    </span>
  );
};
