import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { MODEL_TYPE } from 'features/model-listing/constants';
import { ModelList } from 'features/model-listing/types';
import { NavLink } from 'react-router-dom';
import { ModelStatusCard } from './ModelStatusCard';

import { UnstyledLink } from '@uss/react-core';
interface ModelCardProps {
  modelListItem: ModelList;
}

export const ModelCard = ({ modelListItem }: ModelCardProps) => {
  const modalTypeVal = modelListItem.modelType.toLowerCase();
  return (
    <>
      <Card>
        <UnstyledLink
          as={NavLink}
          key={modelListItem.id}
          to={`${modelListItem.id}`}
        >
          <Card.Body>
            <div className="sm:flex items-center w-full h-full ">
              <div className="flex flex-wrap justify-between items-center lg:flex-nowrap w-full lg:w-full lg:pl-1 lg:pr-5">
                <ModelStatusCard record={modelListItem} />
                <LabeledText
                  label="Pay Loc"
                  lineClamp={1}
                  className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0 sm:pl-4"
                >
                  {modalTypeVal === MODEL_TYPE.GLOBAL && '-'}
                </LabeledText>
                <LabeledText
                  label="Week(s)"
                  lineClamp={1}
                  className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0"
                >
                  {modelListItem.totalWeeks}
                </LabeledText>
                <LabeledText
                  label="Crew Rotation(s)"
                  lineClamp={1}
                  className="w-1/2 lg:w-4/12 mb-2 last-of-type:mb-0 lg:mb-0"
                >
                  {modelListItem.totalCrewRotations}
                </LabeledText>
                <LabeledText
                  label="Model Owner"
                  lineClamp={1}
                  className="w-1/2 lg:w-5/12 mb-2 last-of-type:mb-0 lg:mb-0"
                >
                  {modelListItem.owner ? modelListItem.owner.displayName : '-'}
                </LabeledText>
                <LabeledText
                  label="Last Update On"
                  lineClamp={1}
                  className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
                >
                  {modelListItem.lastUpdatedOn}
                </LabeledText>
              </div>
            </div>
          </Card.Body>
        </UnstyledLink>
      </Card>
    </>
  );
};
