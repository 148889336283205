import {
  CrewEmployeeType,
  EmployeeTimecardList,
  TIMECARD_STATUS,
} from 'features/timecards/types';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';
export const defaultTimecard = (dates: string[]): EmployeeTimecardList => {
  return {
    items: [
      {
        id: 'temp',
        crewId: '',
        crewKey: '',
        ussId: 0,
        week: '',
        payPeriodStatus: PAY_PERIOD_STATUS.CLOSED,
        employeeType: CrewEmployeeType.DEFAULT,
        adjustment: 0,
        adjustmentVersions: [0],
        currentPayPeriodStatus: PAY_PERIOD_STATUS.CLOSED,
        status: {
          code: TIMECARD_STATUS.NOT_VERIFIED,
          description: '',
          primaryApprover: null,
          actionTakenOn: null,
          actionTakenBy: null,
          totalPayableHours: null,
        },
        timecard: dates.map((date) => {
          return {
            id: 'temp',
            date: date,
            elapsedTime: null,
            scheduled: {
              turn: '',
              hours: null,
            },
            worked: {
              turn: '',
              hours: null,
            },
            approved: {
              turn: '',
              hours: null,
            },
            scheduleVariance: {
              payCode: '',
              variance: 0,
            },
            paidHoursNotWorked: null,
            exceptionCode: null,
            exceptionCodeDescription: null,
            comments: '',
            blank: true,
            ptoDetails: null,
          };
        }),
        totalHours: {
          scheduled: null,
          worked: null,
          approved: null,
          scheduleVariance: null,
          paidHoursNotWorked: null,
        },
      },
    ],
  };
};
