export const MODEL_LIST_SORT_OPTIONS = {
  MODEL_NAME: 'modelName',
} as const;

export const MODEL_FILTER_TYPE = {
  GLOBAL: 'global',
  PLANT: 'plant',
  ALL: 'all',
} as const;

export const MODEL_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  CHECKED_OUT: 'checked-out',
  ARCHIVED: 'archived',
};

export const MODEL_SORT = {
  MODEL_NAME: 'modelName',
  LAST_UPDATED_ON: 'lastUpdatedOn'
} as const;

export const MODEL_TYPE = {
  GLOBAL: 'global',
  PLANT: 'plant',
} as const;