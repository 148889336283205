import { Chip, ChipGroup, IconButton } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import Sort from 'components/Sort/Sort';
import { ModelListingDrawer } from 'features/model-listing/components/ModelListing/ModelListingDrawer';
import { ModelSort, ModelType, SortOptionType } from 'features/model-listing/types';
import { useState } from 'react';
import type { Selection } from 'react-aria-components';
import { BsExclamationCircle, BsFunnel, BsPlus } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { SortDirection } from 'types/common';
import ModelListing from 'features/model-listing/components/ModelListing/ModelListing';
import { useModelListFiltersStore } from 'features/model-listing/state/model-list-store';
import useWindowSize from 'hooks/useWindowSize';
import { AutoSearch } from '@uss/react-core';
import CreateModelDrawer from 'features/model-listing/components/ModelListing/create-model';
import { Button } from '@uss/react-components';

const sortOptions: SortOptionType[] = [
  {
    label: 'Model Name',
    name: 'modelName',
    direction: SortDirection.ASC,
  },
  {
    label: 'Model Name',
    name: 'modelName',
    direction: SortDirection.DESC,
  },
  {
    label: 'Last Updated On',
    name: 'lastUpdatedOn',
    direction: SortDirection.ASC,
  },
  {
    label: 'Last Updated On',
    name: 'lastUpdatedOn',
    direction: SortDirection.DESC,
  },
];

export const Modeling = () => {
  const store = useModelListFiltersStore();
  const { width } = useWindowSize();
  const showAlert = width && width > 768 ? false : true;
  //searchbox
  const [searchValue, setSearchValue] = useState<string>('');
  //filter
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  //create-model-drawer
  const [openCreateModelDrawer, setOpenCreateModelDrawer] = useState(false);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    store.setSearch(value);
  };
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));

  const handleModelTypeSelection = (keys: Selection) => {
    const modelTypeVal = Array.from(keys).map((s) => s)[0] as ModelType;
    store.setModelType(modelTypeVal.toLowerCase() as ModelType);
    setSelectedKeys(keys);
  };

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/scheduling">
          Scheduling
        </NavLink>,
      ]}
      heading={'Modeling'}
    >
      <Page.Section bg="gray">
        {showAlert && (
          <div className="sm:hidden bg-blue-100 py-2 px-3 mb-5 border-blue-400 border-2 rounded-md flex justify-between items-center text-blue-900">
            <div className="flex justify-start items-center">
              <BsExclamationCircle size="1.5rem" className="mr-2" />
              Use desktop version for Copy, Edit and Creating model
            </div>
          </div>
        )}
        <div className="w-full sm:flex sm:flex-row">
          <div className="sm:w-3/4 w-full md:grid grid-cols-3 gap-2  ">
            <AutoSearch
              id="search-new"
              placeholder="Search Model Name"
              value={searchValue}
              onChange={(e) => {
                handleSearchChange(e);
              }}
            />
          </div>
          <div className="flex sm:w-1/4 w-full sm:mt-0 mt-4 2xl:justify-end xl:justify-end lg:justify-end md:justify-end pl-4">
            <div className=" flex-row text-primary-400 font-semibold flex items-center">
              <Button
                variant="text"
                onClick={() => setOpenCreateModelDrawer(true)}
              >
                <BsPlus size="1.5rem" />
                <span className="ml-2">Create Model</span>
              </Button>
            </div>
          </div>
        </div>
      </Page.Section>
      <Page.Section>
        <div className="sm:flex sm:flex-row w-full">
          <div className="flex flex-row gap-4 w-1/2">
            <ChipGroup
              selectionMode="single"
              aria-label="ModelType"
              onSelectionChange={(keys) => handleModelTypeSelection(keys)}
              selectedKeys={selectedKeys}
            >
              <Chip size="large" id={'All'}>
                All
              </Chip>
              <Chip size="large" id={'Global'}>
                Global
              </Chip>
              <Chip size="large" id={'Plant'}>
                Plant
              </Chip>
            </ChipGroup>
          </div>
          <div className="flex flex-row sm:w-1/2 gap-4 sm:mt-0 mt-4 w-full 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:pl-4">
            <IconButton
              variant="icon"
              className="cursor-pointer flex items-center justify-center"
              onPressChange={() => setOpenFilterDrawer(true)}
            >
              <>
                <BsFunnel size="1.5rem" className="text-primary-400" />
                {filtersCount > 0 && (
                  <span
                    className={`h-2 w-2 p-0.5 text-xs z-30 relative mb-3 left-4 bottom-0.5 bg-blue-700 rounded-lg`}
                  ></span>
                )}
              </>
            </IconButton>
            <div className="flex md:w-2/6 items-center justify-end w-full">
              <Sort<ModelSort>
                options={sortOptions}
                selectedOption={
                  sortOptions.filter(
                    (value) => store.params.sortType === value.direction
                  )[0]
                }
                onChange={(value) => store.setSort(value)}
              />
            </div>
          </div>
        </div>
      </Page.Section>
      <Page.Section>
        <ModelListing setFiltersCount={setFiltersCount} />
      </Page.Section>
      <ModelListingDrawer
        openFilterDrawer={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      />
      <CreateModelDrawer
        openDrawer={openCreateModelDrawer}
        setOpenDrawer={setOpenCreateModelDrawer}
      />
    </Page>
  );
};
