import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { GetMileageQueryParams, MileageTabStatus } from '../types/mileageEntry';
import { Org } from 'features/org';
import { format } from 'date-fns';

interface MileageApprovalState {
  params: GetMileageQueryParams;
  status: MileageTabStatus;
  selectedPayrollLocation: Org | 'all' | null;
  selectedDate: string | null;
  setPayrollLocation: (value: Org | 'all') => void;
  setStatus: (value: MileageTabStatus) => void;
  setTravelDate: (value: Date | null) => void;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  onClearDate: () => void;
}

export const useMileageApprovalStore = create<MileageApprovalState>()(
  devtools(
    immer((set) => ({
      params: {
        page: 1,
        pageSize: 9,
      },
      selectedPayrollLocation: null,
      status: 'PENDING',
      selectedDate: null,
      setStatus: (value) =>
        set(
          (state) => {
            state.params.status = value;
          },
          false,
          'set-mileage-status'
        ),
      setPayrollLocation: (value) => {
        set(
          (state) => {
            if (value === 'all') {
              state.selectedPayrollLocation = 'all';
              delete state.params.payrollLocation;
            } else {
              state.selectedPayrollLocation = value;
              state.params.payrollLocation = value.code;
            }
          },
          false,
          'set-mileage-payrollLocation'
        );
      },
      setTravelDate: (value) =>
        set(
          (state) => {
            if (value !== null)
              state.params.travelDate = format(value, 'yyyy-MM-dd');
            else delete state.params.travelDate;
          },
          false,
          'set-travel-date'
        ),
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-mileage-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = 1;
          },
          false,
          'set-mileage-pageSize'
        ),
      onClearDate: () =>
        set(
          (state) => {
            delete state.params.travelDate;
          },
          false,
          'set-date-clear'
        ),
    }))
  )
);
