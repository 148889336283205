import { Button, IconButton, Modal, Sidepane } from '@uss/react-core';
import CreateModelForm, { CreateModel } from '../../Forms/CreateModelForm';
import { BsX } from 'react-icons/bs';
import { useState } from 'react';
import { MODEL_TYPE } from 'features/model-listing/constants';
import { useCreateModel } from 'features/model-listing/api/mutations';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';

interface CreateModelDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
}

export default function CreateModelDrawer({
  openDrawer,
  setOpenDrawer,
}: CreateModelDrawerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState<CreateModel | null>(null);

  //current logged-in user
  const { roles } = useUserRoles();

  //create-model api call
  const { mutateAsync } = useCreateModel();

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleModelCreate = (data: CreateModel) => {
    setIsOpen(true);
    setPayload(data);
  };

  const fnCreateModel = async () => {
    if (payload !== null) {
      if (payload.modelType === MODEL_TYPE.GLOBAL) {
        //removing plant and payroll-location details from payload
        const { plantOrgId, locationOrgId, ...globalPayload } = payload;
        setIsOpen(false);
        await mutateAsync(globalPayload);
        handleClose();
      } else {
        //removing weekBeginIndex from payload
        const { weekBeginIndex, ...plantPlayload } = payload;
        //if payroll=All; remove it from payload
        if (payload.locationOrgId === '0') delete plantPlayload.locationOrgId;
        setIsOpen(false);
        await mutateAsync(plantPlayload);
        handleClose();
      }
    }
  };

  let isPlantModel = false;
  if (
    roles[0] === ROLES.PAYROLL_ADMIN ||
    roles[0] === ROLES.T_A_PLANT_COORDINATOR ||
    roles[0] === ROLES.PAY_APPROVER
  )
    isPlantModel = true;

  return (
    <>
      <Sidepane
        ariaLabel="filter"
        placement={'right'}
        isOpen={openDrawer}
        onOpenChange={handleClose}
      >
        <div className="w-full flex gap-10 items-center">
          <h3 className="text-lg flex-grow">Create Model</h3>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </div>
        <CreateModelForm
          defaultValues={{
            modelName: '',
            modelDescription: '',
            modelType: isPlantModel ? 'plant' : 'global',
            weekBeginIndex: 0,
            plantOrgId: '',
            locationOrgId: '',
            totalWeeks: 0,
            totalCrewRotations: 0,
          }}
          handleClose={handleClose}
          handleModelCreate={handleModelCreate}
          isPlantModel={isPlantModel}
        />
      </Sidepane>
      {isOpen && (
        <Modal ariaLabel="" size="default" isOpen={isOpen}>
          <Modal.Header>Create Model</Modal.Header>
          <Modal.Content>
            <p className="text-sm text-gray pb-8">
              Are you sure you want to create a new model?
            </p>
          </Modal.Content>
          <Modal.Footer>
            <div className="sm:flex sm:justify-between sm:gap-2">
              <Button
                variant="outline"
                color={'primary'}
                full={false}
                type="button"
                onPressChange={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color={'primary'}
                full={false}
                type={'submit'}
                onPressChange={fnCreateModel}
              >
                Create
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
