import Page from 'components/Layouts/Page';
import { MileageApprovalCard } from '../MileageApprovalCard';
import useUserRoles from 'hooks/useUserRoles';
import { useMileageApprovalDetails } from 'features/mileage-approval/api/queries';
import CardsLoader from 'components/Loaders/CardsLoader';
import NoResults from 'components/NoResults/NoResults';
import { useEffect } from 'react';
import { useMileageApprovalStore } from 'features/mileage-approval/state/mileage-store';
import Pagination from 'components/Pagination/Pagination';

interface CompletedMileageProps {
  setRecords: (count: string | null) => void;
}

const CompletedCard = ({ setRecords }: CompletedMileageProps) => {
  const { uss_id } = useUserRoles();
  const store = useMileageApprovalStore();
  const { data: mileageDetails, status } = useMileageApprovalDetails(
    uss_id,
    store.params,
    'COMPLETED'
  );

  useEffect(() => {
    //setting status=completed into store
    store.setStatus('COMPLETED');
    //sending mileage-records data to parent
    if (mileageDetails) {
      setRecords(`(${mileageDetails.totalCount})`);
    } else setRecords(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, mileageDetails]);

  return (
    <Page>
      <Page.Section>
        {status === 'pending' && (
          <CardsLoader variant="row-grid" count={9} hasAvatar />
        )}
      </Page.Section>
      <Page.Section>
        {status === 'success' && mileageDetails?.items.length === 0 ? (
          <NoResults
            title="No Records Found!"
            description="No employee records found."
            width="300"
            height="300"
          />
        ) : (
          <>
            {mileageDetails && (
              <MileageApprovalCard mileageDetails={mileageDetails} />
            )}
          </>
        )}
      </Page.Section>
      {mileageDetails && mileageDetails.items.length > 0 && (
        <Page.Section>
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={mileageDetails.totalCount}
            page={Number(store.params.page)}
            pageSize={Number(store.params.pageSize)}
            onPageChange={(s) => store.setPage(s.selected + 1)}
            onPageSizeChange={(ps) => store.setPageSize(ps)}
          />
        </Page.Section>
      )}
    </Page>
  );
};

export default CompletedCard;
