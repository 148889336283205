import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { useModelDetails } from 'features/model-listing/api/queries';
import { ModelDetailTab } from 'features/model-listing/components/ModelDetails/ModelDetailTab';
import { useParams } from 'react-router-dom';
import ComingSoon from 'views/ComingSoon';
import { NavLink } from 'react-router-dom';
import { Detailscard } from 'features/model-listing/components/ModelDetails/ModelDetailsCard';
import { WeekCardLoader } from 'features/model-listing/components/ModelDetails/WeekCardLoader';
import CardsLoader from 'components/Loaders/CardsLoader';

export const ModelDetail = () => {
  const { id = '' } = useParams<'id'>();
  const { data, status } = useModelDetails(id);

  const tabLinks = [
    {
      label: 'Details',
      element: (
        <>
          {status === 'success' ? (
            <ModelDetailTab data={data} />
          ) : (
            <WeekCardLoader
              numberOfWeeks={9}
              numberOfCrewRotations={3}
              numberOfGridCols={3}
            />
          )}
        </>
      ),
    },
    {
      label: 'View Usage',
      element: <ComingSoon />,
    },
    {
      label: 'History',
      element: <ComingSoon />,
    },
  ];
  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="bc-1" to="/scheduling">
            Scheduling
          </NavLink>,
          <NavLink key="bc-2" to="/non-union-scheduling/modeling">
            Modeling
          </NavLink>,
        ]}
        heading={'Model Details'}
      >
        <Page.Section>
          {status === 'success' ? (
            <Detailscard data={data} />
          ) : (
            <CardsLoader variant="row-grid" count={1} hasAvatar />
          )}
        </Page.Section>
        <Page.Section>
          <Tabs tabLinks={tabLinks} />
        </Page.Section>
      </Page>
    </>
  );
};
